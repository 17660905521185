import { useEffect, useState } from "react";
import axios from "axios";
import Slideshow from "./ImageRow";
import "../Styles/Coddingpage.css";
import Software from "./Software";
import Projectlist from "./Projectlist";
import Audiodescription from "./Audiodescription";

const Coddingpage = ({ animationLeave }) => {
  const [selectedIndex, setSelected] = useState(0);

  const projectClick = (index) => {
    setSelected(index);
  };

  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const callAPI = async () => {
      try {
        const response = await axios.get(
          "https://vurtr8xxtc.execute-api.us-east-2.amazonaws.com/Prod/CoddingProjects"
        );
        setProjects([...JSON.parse(response.data.body)]);
      } catch (error) {
        console.error("Error fetching projects");
      }
    };
    callAPI();
  }, []);
  return (
    <div className={animationLeave === true ? "entire-page" : ""}>
      <div className="content-info-container">
        <div className="images-container">
          {projects.length > 0 ? (
            <Slideshow
              content={projects[selectedIndex].content}
              device={projects[selectedIndex].device}
              media={projects[selectedIndex].media}
            ></Slideshow>
          ) : (
            <Slideshow></Slideshow>
          )}
        </div>
        <div className="content-container">
          <div>
            <div className="title-container">
              {projects.length > 0 ? (
                <a
                  href={projects[selectedIndex].git}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="git-link"
                >
                  {projects[selectedIndex].name}
                </a>
              ) : (
                <></>
              )}
            </div>
            <div className="description-container">
              <Audiodescription
                description={
                  projects.length > 0 ? projects[selectedIndex].description : ""
                }
                link={projects.length > 0 ? projects[selectedIndex].audio : ""}
              ></Audiodescription>
            </div>
            <div className="title-container">
              {projects.length > 0 ? projects[selectedIndex].date : <></>}
            </div>
            <div className="skill-container">
              {projects.length > 0 ? (
                <Software
                  software={projects[selectedIndex].skills}
                  setClass={"icon"}
                ></Software>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="list-container">
            {projects.length > 0 ? (
              <Projectlist
                projectClick={projectClick}
                projects={projects}
              ></Projectlist>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Coddingpage;

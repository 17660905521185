import "../Styles/HomeSlideshow.css";

const SlideshowImage = ({ image, hoverFunction, imageIndex }) => {
  return (
    <div className="crop-image">
      <img
        src={image.src}
        alt="me"
        className={`${image.type}-image-element`}
        onMouseEnter={() => {
          hoverFunction(imageIndex);
          console.log("triggered Hover function");
        }}
      />
    </div>
  );
};

export default SlideshowImage;

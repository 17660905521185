import "../Styles/Coddingpage.css";
const Projectlist = ({ projects, projectClick }) => {
  const nameArray = projects ? projects.map((project) => project.name) : [];
  console.log(nameArray);
  return (
    <>
      {nameArray.map((name, index) => {
        return (
          <>
            <button
              onClick={() => {
                projectClick(index);
              }}
              className="project-button"
            >
              {name}
            </button>
            <br></br>
          </>
        );
      })}
    </>
  );
};
export default Projectlist;

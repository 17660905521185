import axios from "axios";
import { useEffect, useState } from "react";
import "../Styles/HomeSlideshow.css";
import SlideshowImage from "./SlideshowImage";

const Slideshow = () => {
  const [images, setImages] = useState([]);
  const [showHover, setHover] = useState(false);
  const [cursorPosition, setPosition] = useState({ x: 0, y: 0 });
  const [description, setDescription] = useState("");
  const handleMove = (event) => {
    setPosition({ x: event.clientX, y: event.clientY });
  };
  const onHover = (index) => {
    setDescription(images[index].description);
  };
  useEffect(() => {
    const callAPI = async () => {
      try {
        const resource = await axios.get(
          "https://vurtr8xxtc.execute-api.us-east-2.amazonaws.com/Prod/slideshow-images"
        );
        setImages(JSON.parse(resource.data.body));
      } catch (error) {
        console.log("network connection error" + error);
      }
    };
    callAPI();
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", handleMove);
    return () => {
      window.removeEventListener("mousemove", handleMove);
    };
  }, []);
  return (
    <center>
      <div className="img-row-container">
        {showHover === true ? (
          <>
            <p
              className="floating-description"
              style={{
                left: cursorPosition.x,
                top: cursorPosition.y,
              }}
            >
              {description}
            </p>
          </>
        ) : (
          <></>
        )}
        <div
          className="images-row"
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          {images.length > 0 ? (
            <>
              {" "}
              <SlideshowImage
                image={images[images.length - 1]}
                imageIndex={images.length - 1}
                hoverFunction={onHover}
              ></SlideshowImage>
              {images.map((image, index) => {
                return (
                  <>
                    <SlideshowImage
                      image={image}
                      imageIndex={index}
                      hoverFunction={onHover}
                    ></SlideshowImage>
                  </>
                );
              })}
              <SlideshowImage
                image={images[0]}
                hoverFunction={onHover}
                imageIndex={0}
              ></SlideshowImage>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </center>
  );
};
export default Slideshow;

import "./App.css";
import Homepage from "./Components/Homepage";
import { useState } from "react";
import "./Styles/Homepage.css";
import Coddingpage from "./Components/Coddingpage";
import Printingpage from "./Components/Printingpage";
import Filmpage from "./Components/Filmpage";

function App() {
  const [home, setHome] = useState(false);
  const [pageState, setPage] = useState(0);
  const [imageHover, setHover] = useState(false);
  const [animationLeave, setAnimationState] = useState(false);

  const changePage = (pageNumber) => {
    if (pageNumber !== pageState) {
      setAnimationState(true);
      setTimeout(() => {
        setAnimationState(false);
        setPage(pageNumber);
      }, 500);
    }
  };

  return (
    <body className="background">
      <div className="appbar">
        <img
          src="logo.png"
          className="logo-img"
          alt="logo"
          onClick={() => {
            changePage(0);
          }}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        ></img>
        <h1 className={imageHover === true ? "logo-text-hover" : "logo-text"}>
          lec Hallman
        </h1>
        <div className="app-btn-container">
          <button
            className="app-btn"
            onClick={() => {
              changePage(1);
            }}
          >
            Coding
          </button>
          <button
            className="app-btn"
            onClick={() => {
              changePage(2);
            }}
          >
            Film
          </button>
          <button
            className="app-btn"
            onClick={() => {
              changePage(3);
            }}
          >
            3D Printing
          </button>
        </div>
        <a
          href="https://github.com/Alec-Hallman"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="./icons/Github.png"
            className="social-img"
            alt="social-logo"
          ></img>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://drive.google.com/file/d/1KUlap0y5Uka0O5lQ_mFK9KItnfLEm5uT/view?usp=sharing"
        >
          <img
            src="./icons/resume.png"
            className="social-img"
            alt="social-logo"
          ></img>
        </a>
      </div>
      <div className="pages-container">
        {pageState === 0 ? (
          <Homepage
            home={home}
            setHome={setHome}
            animationLeave={animationLeave}
          ></Homepage>
        ) : (
          <></>
        )}
        {pageState === 1 ? (
          <Coddingpage animationLeave={animationLeave}></Coddingpage>
        ) : (
          <></>
        )}
        {pageState === 2 ? (
          <Filmpage animationLeave={animationLeave}></Filmpage>
        ) : (
          <></>
        )}
        {pageState === 3 ? (
          <Printingpage animationLeave={animationLeave}></Printingpage>
        ) : (
          <></>
        )}
      </div>
    </body>
  );
}

export default App;

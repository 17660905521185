import { useEffect, useRef, useState } from "react";
import axios from "axios";
import "../Styles/Printingpage.css";
import "../Styles/Slideshow.css";
import "../Styles/Coddingpage.css";
import Slideshow from "./ImageRow";
import Software from "./Software";
import Audiodescription from "./Audiodescription";
const Printingpage = ({ animationLeave }) => {
  const [projects, setProjects] = useState([]);
  var [index, setIndex] = useState(0);
  const [cubeState, setCube] = useState("cube-loading");
  const [animating, setAnimating] = useState(false);
  const [leftFace, setLeft] = useState("Loading");
  const [frontFace, setFront] = useState("Loading");
  const [rightFace, setRight] = useState("Loading");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setAnimating(true);
    setTimeout(() => {
      if (projects.length > 0) {
        setFront(projects[0].name);
        setCube("cube");
        setAnimating(false);
        setLoading(false);
      }
    }, 2000);
  }, [projects]);

  useEffect(() => {
    const testAPI = async () => {
      try {
        const response = await axios.get(
          "https://vurtr8xxtc.execute-api.us-east-2.amazonaws.com/Prod/PrintingProjects"
        );
        const projectData = JSON.parse(response.data.body);
        setProjects([...projectData]);
      } catch (error) {
        console.error("Error fetching projects", error);
      }
    };
    testAPI();
  }, []);
  const scrollContainer = useRef();
  const rotateCube = (direction) => {
    if (!animating) {
      if (direction === "left") {
        setAnimating(true);
        if (index !== projects.length - 1) {
          setIndex((index += 1));
          setCube("cube-left");
          setLeft(projects[index].name);
        } else {
          setLeft("");
          setCube("cube-left-full");
        }
      } else {
        setAnimating(true);

        if (index !== 0) {
          setIndex((index -= 1));
          setCube("cube-right");
          setRight(projects[index].name);
        } else {
          setRight("");
          setCube("cube-right-full");
        }
      }
      setTimeout(() => {
        //animation is done
        setFront(projects[index].name);
        setAnimating(false);
        setCube("cube");
      }, 670);
    }
  };

  useEffect(() => {
    const handleScroll = (event) => {
      const container = scrollContainer.current;
      if (container) {
        event.stopPropagation();
        if (event.deltaY < 0 && !animating) {
          //animation is starting
          rotateCube("left");
        } else if (!animating) {
          rotateCube("right");
        }
      }
    };
    const container = scrollContainer.current;
    if (container) {
      container.addEventListener("wheel", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("wheel", handleScroll);
      }
    };
  });
  return (
    <div className={animationLeave === true ? "entire-page" : ""}>
      <div className="entire-container">
        <div>
          <div className="cube-row">
            <img
              className="cube-btn"
              onClick={() => {
                rotateCube("left");
              }}
              src="./icons/LeftArrow.png"
              alt="Left Arrow"
            ></img>
            <div className="button-offset"></div>
            <div class="cube-container" ref={scrollContainer}>
              <div class={cubeState}>
                <div class="face front">
                  <p className="name-txt">{frontFace}</p>
                </div>
                <div class="face back">
                  <p className="name-txt">Loading</p>
                </div>
                <div class="face left">
                  <p className="name-txt">{leftFace}</p>
                </div>
                <div class="face right">
                  <p className="name-txt">{rightFace}</p>
                </div>
                <div class="face top"></div>
                <div class="face bottom"></div>
              </div>
            </div>
            <img
              className="cube-btn"
              onClick={() => {
                rotateCube("right");
              }}
              src="./icons/RightArrow.png"
              alt="Right Arrow"
            ></img>
          </div>
          <div className="bottom-row">
            <div className="description">
              <Audiodescription
                link={loading === false ? projects[index].audio : ""}
                description={
                  loading === false ? projects[index].description : ""
                }
              ></Audiodescription>
            </div>
            <div className="software">
              {loading === false ? (
                <Software
                  software={projects[index].software}
                  setClass={"software-icon"}
                ></Software>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="offset-images">
          <div className="images">
            {loading === false ? (
              <Slideshow
                content={projects[index].content}
                device={projects[index].device}
              ></Slideshow>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Printingpage;

import { useEffect, useState } from "react";
import "../Styles/Filmpage.css";
import axios from "axios";
import Software from "./Software";
import Filmloading from "./Filmloading";
import Scrollbar from "./Scrollbar";
import Audiodescription from "./Audiodescription";

const Filmpage = ({ animationLeave }) => {
  const [projIndex, setIndex] = useState(0);
  const [projects, setProjects] = useState([]);
  const [loadding, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const filmClick = (index) => {
    setIndex(index);
  };
  useEffect(() => {
    const testAPI = async () => {
      try {
        const response = await axios.get(
          "https://vurtr8xxtc.execute-api.us-east-2.amazonaws.com/Prod/FilmProjects"
        );
        setProjects([...JSON.parse(response.data.body)]);
      } catch (error) {
        console.error("Error fetching projects", error);
      }
    };
    testAPI();
  }, []);

  useEffect(() => {
    if (projects.length > 0) {
      setTimeout(() => {
        setLoaded(true);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }, 2000);
    }
  }, [projects]);

  return (
    <div className={animationLeave === true ? "entire-page" : ""}>
      {loadding ? (
        <Filmloading loaded={loaded}></Filmloading>
      ) : (
        <div className="film-page">
          <div className="content-row">
            <div className="film-container">
              <div className="film-title">{projects[projIndex].name}</div>
              <iframe
                src={projects[projIndex].url}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                width="100%"
                height="100%"
                allowFullScreen
              ></iframe>
            </div>
            <div className="information-container">
              <div className="film-description">
                <Audiodescription
                  description={projects[projIndex].description}
                  link={projects[projIndex].audio}
                ></Audiodescription>
              </div>
              <div className="date-software-container">
                <div className="software-container">
                  <Software
                    software={projects[projIndex].software}
                    setClass={"software-img"}
                  ></Software>
                </div>
                <div className="film-date">{projects[projIndex].date}</div>
              </div>
            </div>
          </div>
          <div className="bottom-container">
            <Scrollbar images={projects} imageClick={filmClick}></Scrollbar>
          </div>
        </div>
      )}
    </div>
  );
};
export default Filmpage;
